import {
	Stack,
	Divider,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	IconButton,
	Button,
} from '@mui/material';
import { AccountCircle, Delete } from '@mui/icons-material';
import { useAuth } from '../../Contexts/AuthContext';

export const SettingsPage = () => {
	const { username, email } = useAuth();
	const accountNames:string[] = [];
	const accountEmails:string[] = [];
	

	return (
		<Stack
			direction="column"
			flex={1}
			divider={
				<Divider
					orientation="horizontal"
					flexItem
				/>
			}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				paddingX="20px"
				paddingY="20px"
			>
				<Typography
					fontSize="25px"
					fontWeight="bold"
				>
					Settings
				</Typography>
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				spacing="20px"
				paddingX="30px"
				paddingY="30px"
			>
				<Typography
					fontSize="20px"
					fontWeight="bold"
				>
					Profile
				</Typography>
				<Stack
					direction="column"
					alignItems="start"
					justifyContent="center"
					spacing="5px"
					paddingLeft="20px"
				>
					<Stack
						direction="row"
						width="100%"
						spacing={2}
						alignItems="center"
					>
						<Typography
							fontSize="15px"
							fontWeight="bold"
						>
							User Name
						</Typography>
						<Typography color="grey">
							{username}
						</Typography>
					</Stack>
					<Stack
						direction="row"
						width="100%"
						spacing={2}
						alignItems="center"
					>
						<Typography
							fontSize="15px"
							fontWeight="bold"
						>
							Email
						</Typography>
						<Typography color="grey">
							{email}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				justifyContent="start"
				spacing="20px"
				paddingX="30px"
				paddingY="30px"
			>
				<Stack
					direction="row"
					width="100%"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography
						fontSize="20px"
						fontWeight="bold"
					>
						Linked Accounts
					</Typography>
					<Button
						variant="contained"
						style={{
							textTransform:
								'none',
						}}
					>
						Add Account
					</Button>
				</Stack>
				<TableContainer>
					<Table
						style={{
							border: '1px solid rgb(0,0,0,0.1)',
						}}
					>
						<TableBody>
							{accountNames.map(
								(
									a,
									i
								) => (
									<TableRow>
										<TableCell align="center">
											<Stack
												direction="row"
												spacing={
													1
												}
											>
												<AccountCircle />
												<Typography>
													{
														a
													}
												</Typography>
											</Stack>
										</TableCell>
										<TableCell align="left">
											<Typography>
												{
													accountEmails[
														i
													]
												}
											</Typography>
										</TableCell>
										<TableCell align="right">
											<IconButton
												children={
													<Delete />
												}
											/>
										</TableCell>
									</TableRow>
								)
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				justifyContent="start"
				spacing="10px"
				paddingX="30px"
				paddingTop="30px"
			>
				<Typography
					fontSize="20px"
					fontWeight="bold"
					color="darkred"
				>
					Delete Account
				</Typography>
				<Stack
					direction="column"
					alignItems="start"
					justifyContent="center"
					spacing="10px"
				>
					<Typography>
						Once you delete your
						account, there is no
						going back. Please
						be certain.
					</Typography>
					<Button
						variant="contained"
						style={{
							paddingLeft: '20px',
							paddingRight: '20px',
							backgroundColor:
								'red',
							textTransform:
								'none',
						}}
					>
						Delete account
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
