import {
	CalendarMonth,
	PeopleOutlined,
	Search,
	UploadFile,
	ViewAgendaOutlined
} from '@mui/icons-material';
import {
	Stack,
	Divider,
	Typography,
	Button,
	TextField,
	InputAdornment,
	Table,
	TableHead,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../ApplicationRoutes';
import { StatsBox } from './StatsBox';
import { useEffect, useState } from 'react';
import { GroupScheduleRow } from './GroupScheduleRow';
import apiClient from '../../ApiClient';
import axios from 'axios';
import { ErrorDialog } from '../../Helpers/ErrorDialog';
import { TiktokAccount, Video } from '../Schedule/SchedulePage';
import { groupBy, min } from 'lodash';

export interface RecurringSchedule {
	user: number;
	id: number;
	group: string;
	post_time_1: string;
	post_time_2: string;
	post_time_3: string;
	post_time_4: string;
	post_time_5: string;
	tiktok_account: number;
}

export const DashboardPage = () => {
	const navigate = useNavigate();

	const [videos, setVideos] = useState<Video[]>([]);
	const [schedules, setSchedules] = useState<RecurringSchedule[]>([]);
	const [searchText, setSearchText] = useState('');
	const [accounts, setAccounts] = useState<TiktokAccount[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [errorTitle, setErrorTitle] = useState<string | null>(null);

	useEffect(() => {
		getRecurringSchedules();
		getVideos();
		getTiktokAccounts();
	}, []);

	const getRecurringSchedules = async () => {
		await apiClient.get<RecurringSchedule[]>("core/recurringschedule/")
		.then(response => {
			const scheduleResponse = response.data;
			setSchedules(scheduleResponse);
		}).catch((err) => {
			setErrorTitle("Failed to fetch schedules")
			if (axios.isAxiosError(err)) {
				const errorMessage = err.response?.data?.message || 'An error occurred while fetching schedules.';
				setError(errorMessage);
			} else {
				setError('An unexpected error occurred while fetching schedules.');
			}
		});
	}

	const getVideos = async () => {
		await apiClient.get<Video[]>("core/videos/")
		.then(response => {
			const videosResponse = response.data;
			setVideos(videosResponse);
		}).catch((err) => {
			setErrorTitle("Failed to fetch videos")
			if (axios.isAxiosError(err)) {
				const errorMessage = err.response?.data?.message || 'An error occurred while fetching videos.';
				setError(errorMessage);
			} else {
				setError('An unexpected error occurred while fetching videos.');
			}
		});
	};

	const getTiktokAccounts = async () => {
		await apiClient.get<TiktokAccount[]>("core/tiktokaccounts/")
		.then(response => {
			const accountsResponse = response.data;
			setAccounts(accountsResponse);
		}).catch((err) => {
			setErrorTitle("Failed to fetch tiktok accounts")
			if (axios.isAxiosError(err)) {
				const errorMessage = err.response?.data?.message || 'An error occurred while fetching tiktok accounts.';
				setError(errorMessage);
			} else {
				setError('An unexpected error occurred while fetching tiktok accounts.');
			}
		});
	}

	const getTodaysPosts = () => {
		const currDate = new Date();
		const currentTime = currDate.getHours()*3600 + currDate.getMinutes()*60 + currDate.getSeconds();
		let posts = 0;
		for(const schedule of schedules){
			const scheduleVids = videos.filter(v => v.group === schedule.group);
			const times = [schedule.post_time_1, schedule.post_time_2, schedule.post_time_3, schedule.post_time_4, schedule.post_time_5]
			.filter(t => t)
			.map(t => {
				const [hours, minutes, seconds] = t.split(':').map(Number);
				return hours*3600 + minutes*60 + seconds;
			});
			posts += min([times.filter(t => t < currentTime).length, scheduleVids.filter(v => v.posted).length]) ?? 0;
			posts += min([times.filter(t => t >= currentTime).length, scheduleVids.filter(v => !v.posted).length]) ?? 0;
		}
		return posts;
	}

	return (
		<>
			<Stack
				direction="column"
				flex={1}
				divider={
					<Divider
						orientation="horizontal"
						flexItem
					/>
				}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					paddingX="20px"
					paddingY="20px"
				>
					<Typography
						fontSize="25px"
						fontWeight="bold"
					>
						Dashboard
					</Typography>
					<Stack
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Button
							variant="contained"
							style={{
								textTransform:
									'none',
							}}
							onClick={() => {
								navigate(
									AppRoutes.Upload
								);
							}}
						>
							Upload Videos
						</Button>
						<Button
							variant="contained"
							style={{
								textTransform:
									'none',
							}}
							onClick={() => {
								navigate(
									AppRoutes.Schedule
								);
							}}
						>
							Schedule New Posts
						</Button>
					</Stack>
				</Stack>
				<Stack
					direction="column"
					height="100%"
					alignItems="center"
					justifyContent="start"
					spacing="20px"
					paddingX="30px"
					paddingTop="30px"
				>
					<Stack
						direction="row"
						width="100%"
						justifyContent="space-around"
						spacing="10px"
					>
						<StatsBox
							title={
								"Today's Upload"
							}
							stat={getTodaysPosts()}
						>
							<UploadFile />
						</StatsBox>
						<StatsBox
							title={
								'Total Posts'
							}
							stat={videos.filter(v => v.posted).length}
						>
							<ViewAgendaOutlined />
						</StatsBox>
						<StatsBox
							title={
								'Scheduled Posts'
							}
							stat={videos.filter(v => !v.posted).length}
						>
							<CalendarMonth />
						</StatsBox>
						<StatsBox
							title={
								'Total Accounts'
							}
							stat={accounts.length}
						>
							<PeopleOutlined />
						</StatsBox>
					</Stack>
					<TextField
						size="small"
						fullWidth
						value={searchText}
						onChange={e =>
							setSearchText(
								e.target
									.value
							)
						}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							),
						}}
					/>
					<TableContainer>
						<Table
							style={{
								border: '1px solid rgb(0,0,0,0.1)',
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										Group
									</TableCell>
									<TableCell align="center">
										Videos
									</TableCell>
									<TableCell align="center">
										Posted
									</TableCell>
									<TableCell align="center">
										Schedule Type
									</TableCell>
									<TableCell align="center">
										Account
									</TableCell>
									{/* <TableCell align="center">
									</TableCell> */}
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.entries(
									groupBy(
										videos.filter(v => 
											(!searchText || searchText.length === 0) ? 
												true:
												v.group.includes(searchText)), 
												v => v.group
									)
								).map(([g, vs]) => (
									schedules.filter(s => s.group === g).map(s => (
										<GroupScheduleRow
											group={g}
											videos={vs}
											schedule={s}
											account={accounts.find(a => a.id === s.tiktok_account)}
										/>
									))
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Stack>
			<ErrorDialog
				title={errorTitle}
				error={error}
				onClose={() => setError(null)}
			/>
		</>
	);
};
